'use client'
import useAuthData from '@/containers/authentication/dataSources/authData.slice'
import { useRedirector } from '@/containers/navigation/useNavigator'

const RootPage = () => {
  const { session } = useAuthData()
  const redirect = useRedirector()
  return redirect(!!session ? 'dashboard' : 'login')
}

export default RootPage
